import {
  motion,
  useMotionTemplate,
  useScroll,
  useSpring,
  useTransform,
} from "framer-motion"

export default function ImageFader(props: { children: React.ReactNode }) {
  const { scrollYProgress } = useScroll({
    offset: ["0px", "1600px"],
  })

  const opacity = useSpring(scrollYProgress, { stiffness: 500, damping: 90 })
  const blurValue = useTransform(scrollYProgress, [0.0, 1], [50, 0])
  const scaleValue = useSpring(useTransform(scrollYProgress, [1, 1], [1, 1]), {
    stiffness: 400,
    damping: 90,
  })
  const filter = useMotionTemplate`blur(${blurValue}px)`
  const transform = useMotionTemplate`scale(${scaleValue})`

  return (
    <div className="">
      <motion.div
        style={{ opacity, filter, transform, WebkitTransform: transform }}
      >
        <div>{props.children}</div>
      </motion.div>
    </div>
  )
}
